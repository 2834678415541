import {Injectable} from '@angular/core';
import {PromptResult} from "../capture/capture.service";
import {StateService} from "./state.service";

export interface Theme {
  primaryColor: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private readonly stateService: StateService) {
  }

  goldbetTheme: Theme = {
    primaryColor: '#FFCC00'
  };

  promptbetTheme: Theme = {
    primaryColor: '#0090ff'
  };

  betterTheme: Theme = {
    primaryColor: '#1bb14c'
  };

  betflagTheme: Theme = {
    primaryColor: '#42aee8'
  };

  bet2dayTheme: Theme = {
    primaryColor: '#EE8201'
  }

  public injectTheme() {
    let theme: Theme;
    if (this.stateService.user?.client?.theme === 'goldbet') {
      this.stateService.theme = 'goldbet';
      this.stateService.clientLogo = 'assets/logos/goldbet.svg';
      theme = this.goldbetTheme;
    } else if (this.stateService.user?.client?.theme === 'better') {
      this.stateService.theme = 'better';
      this.stateService.clientLogo = 'assets/logos/better.png';
      theme = this.betterTheme;
    } else if (this.stateService.user?.client?.theme === 'betflag') {
      this.stateService.theme = 'betflag';
      this.stateService.clientLogo = 'assets/logos/betflag.png';
      theme = this.betflagTheme;
    } else if (this.stateService.user?.client?.theme === 'bet2day') {
      this.stateService.theme = 'bet2day';
      this.stateService.clientLogo = 'assets/logos/bet2day-dark.png';
      theme = this.bet2dayTheme;
    } else {
      this.stateService.theme = 'promptbet';
      this.stateService.clientLogo = 'assets/logos/promptbet.png';
      theme = this.promptbetTheme;
    }
    const existingStyleEl = document.getElementById('dynamic-theme-style');
    if (existingStyleEl) {
      existingStyleEl.remove();
    }

    const styleEl = document.createElement('style');
    styleEl.id = 'dynamic-theme-style';
    styleEl.textContent = `:root {
      --primary-color: ${theme.primaryColor};
    }
  `;
    console.log('STYLE', this.stateService.theme, styleEl);
    document.head.appendChild(styleEl);
  }
};
